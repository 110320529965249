<template>
  <LoadingWrapper :loading="loading">
    <div class="button-box">
      <IxRes>{{ `dlgCert.management.${form}HelpText` }}</IxRes>
      <IxButton
        class="upload-file-btn"
        icon="download"
        rounded
        @click="downloadFile('form')"
      >
        <IxRes>{{ `dlgCert.management.buttons.${form}DownloadForm` }}</IxRes>
      </IxButton>
      <IxButton
        class="upload-file-btn"
        icon="download"
        rounded
        @click="downloadFile('help')"
      >
        <IxRes>{{ `dlgCert.management.buttons.${form}DownloadInstructions` }}</IxRes>
      </IxButton>
      <IxButton
        class="upload-file-btn"
        :class="[fileWasUploaded ? 'btn-secondary custom-colored-btn' : 'btn-primary']"
        icon="upload"
        rounded
        @click="openFileSelection(fileName)"
      >
        <IxRes>{{ `dlgCert.management.buttons.${fileWasUploaded ? 'uploadNewerForm' : 'uploadForm'}` }}</IxRes>
      </IxButton>
      <div v-if="fileWasUploaded" class="uploaded-file-container">
        <a :href="fileRef" :download="fileName" data-ajax="false">{{ fileName }}</a>
        <IxButton icon="trash" @click="deleteFile" />
      </div>
      <input
        ref="fileInput" type="file"
        name="file" class="fileinput"
        accept=".doc, .docx"
        @change="uploadForm"
      >
    </div>
  </LoadingWrapper>
</template>

<script>
import moment from 'moment'
import {trim} from 'lodash'
import {notifications} from 'src/js/infrastructure'

import LoadingWrapper from './shared/LoadingWrapper'
import IxButton from '@components/IxButton'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

function saveFile (data, filename) {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  link.dataset.ajax = false
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default {
  components: {
    IxButton,
    LoadingWrapper
  },
  mixins: [
    ApiMixin
  ],
  props: {
    form: String
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/management/${this.$route.params.year}/${this.form}`
    },
    fileWasUploaded () {
      return !!this.fileName
    },
    fileName () {
      if (this.data === null || this.data === undefined) {
        return ''
      }
      return this.data.uploadedFile || ''
    },
    fileRef () {
      return `${this.apiUrl}/files?filename=${this.fileName}`
    }
  },
  methods: {
    downloadFile (type) {
      var fileName = `${this.form + type}`
      this.api.get(fileName, {responseType: 'blob'})
      .then((response) => {
        const fileName = response.headers['content-disposition']
        .split(';')
        .map(str => str.trim())
        .find(str => str.startsWith('filename='))
        .split('=')[1]

        saveFile(response.data, trim(fileName, '"'))
      })
    },
    openFileSelection (formName) {
      this.$refs.fileInput.click()
    },
    async deleteFile () {
      await this.api.delete(this.fileName)
        .then(() => { this.data.uploadedFile = '' })
        .catch((error) => { notifications.warning(error) })
    },
    async uploadForm (form) {
      let file = form.target.files[0]
      let uploadedOn = moment().format('L')
      let formData = new FormData()
      formData.append('formFile', file)
      formData.append('uploadedOn', uploadedOn)
      try {
        await this.api.post('', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(() => {
              this.data.uploadedFile = file.name
              this.$refs.fileInput.value = '' // workaround to trigger change event of file-input on chrome
            })
      } catch (error) {
        notifications.error(error)
        this.$refs.fileInput.value = '' // workaround to trigger change event of file-input on chrome
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .fileinput {
    display: none !important;
  }

  .button-box{
    display: grid;
    grid: auto auto auto / min-content min-content;
    grid-gap: 10px;

    *:first-child{
      grid-column: 1 / span 2;
    }

    .uploaded-file-container {
      display: flex;
      justify-content: space-between;
    }
  }
</style>
